import Vue from 'vue'
import { findIndex, find, isEmpty, get, uniqBy } from 'lodash-es'
import { registerService } from '@/services/api/register'
import { DOCUMENT_TYPES } from '@/constants'
import { TYPES as TYPES_REGISTER_OF_REQUIREMENTS } from '@src/modules/registers/requirements/constants'

function getInitialState () {
    return {
        requirements: [],
        stepRequirements: [],
        companyRegulationRegisters: [],
        currentCompanyRegulationRegister: {},
        registerDetails: [],
        registerProperties: [],
        registersAndModules: {},
        registerChangeHistory: [],
        relatedDocuments: []
    }
}

const state = getInitialState()

const actions = {
    async getStepRequirements ({ commit, dispatch, rootGetters }, stepId) {
        try {
            const companyId = rootGetters['company/company'].id
            const currentRegulationId =
                rootGetters['regulation/currentRegulation'].id
            const requirements = await registerService.getStepRequirements(
                companyId,
                currentRegulationId,
                stepId
            )

            commit('SET_STEP_REQUIREMENTS', requirements)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        }
    },

    async updateCompanyRegulationRegister (
        { commit, dispatch, rootGetters },
        { registerId, payload }
    ) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            await registerService.updateCompanyRegulationRegister(
                companyId,
                regulationId,
                registerId,
                payload
            )

            commit('UPDATE_COMPANY_REGULATION_REGISTER', {
                registerId,
                payload
            })
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getCompanyRegulationRegisters ({ commit, dispatch, rootGetters }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            if (!companyId || !regulationId) {
                return
            }

            const registers = await registerService.getCompanyRegulationRegisters(
                companyId,
                regulationId
            )

            commit('SET_COMPANY_REGULATION_REGISTERS', registers)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getCurrentCompanyRegulationRegister (
        { commit, dispatch, getters, rootGetters },
        registerId
    ) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const register = await registerService.getCompanyRegulationRegister(
                companyId,
                regulationId,
                registerId
            )

            commit('SET_CURRENT_COMPANY_REGULATION_REGISTER', register)
            commit('SET_REGISTER_PROPERTIES', [])

            if (!isEmpty(getters.currentCompanyRegulationRegister)) {
                const users = rootGetters['company/usersById']

                commit(
                    'comment/SET_CURRENT_DISCUSSIONS',
                    {
                        discussions:
                            getters.currentCompanyRegulationRegister
                                .discussions,
                        users
                    },
                    { root: true }
                )
                commit(
                    'comment/SET_CURRENT_COMMENT_TARGETABLE_TYPE',
                    DOCUMENT_TYPES.COMPANY_REGULATION_REGISTER,
                    { root: true }
                )
                commit(
                    'comment/SET_CURRENT_COMMENT_TARGETABLE_ID',
                    getters.currentCompanyRegulationRegister.id,
                    { root: true }
                )
                commit(
                    'SET_REGISTER_PROPERTIES',
                    getters.currentCompanyRegulationRegister.register.meta
                )
            }
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getRegisterChangeHistory (
        { commit, rootGetters, dispatch },
        registerId
    ) {
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const changeHistory = await registerService.getRegisterChangeHistory(
                companyId,
                regulationId,
                registerId
            )
            commit('SET_REGISTER_CHANGE_HISTORY', changeHistory)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        }
    },

    async getRegisterDetails (
        { commit, rootGetters, dispatch },
        { registerId }
    ) {
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const details = await registerService.getRegisterDetails(
                companyId,
                regulationId,
                registerId
            )
            commit('SET_REGISTER_DETAILS', details)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        }
    },

    async updateRegisterProperties (
        { commit, rootGetters, dispatch },
        { registerId, payload }
    ) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            await registerService.updateRegisterProperties(
                companyId,
                regulationId,
                registerId,
                payload
            )

            // Register data should be updated
            await dispatch('getCompanyRegulationRegisters')
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async getRegistersAndModules ({ commit, rootGetters, dispatch }) {
        commit('SET_APP_LOADING', true, { root: true })
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id

            const data = await registerService.getDataForRegistersAndModulesPage(
                companyId,
                regulationId
            )

            commit('SET_REGISTERS_AND_MODULES', data)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        } finally {
            commit('SET_APP_LOADING', false, { root: true })
        }
    },

    async markVideoAsSeen (
        { commit, rootGetters, getters, dispatch },
        videoId
    ) {
        try {
            const companyId = rootGetters['company/company'].id
            const regulationId = rootGetters['regulation/currentRegulation'].id
            const registerId =
                getters.currentCompanyRegulationRegister.register_id

            await registerService.markVideoAsSeen(
                companyId,
                regulationId,
                registerId,
                videoId
            )

            commit('MARK_CURRENT_REGISTER_VIDEO_AS_SEEN', videoId)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
        }
    },

    async getRelatedDocuments ({ commit, rootGetters, dispatch }, registerId) {
        try {
            const companyId = rootGetters['company/company'].id

            const regulationId = rootGetters['regulation/currentRegulation'].id

            const data = await registerService.getRelatedDocuments(
                companyId,
                regulationId,
                registerId
            )

            commit('SET_RELATED_DOCUMENTS', data)
        } catch (error) {
            dispatch('errors/handleError', error, { root: true })
            throw error
        }
    },

    async getCurrentStepRelatedDocuments ({ getters, dispatch }) {
        const registerId = getters.currentCompanyRegulationRegister?.register_id

        if (!registerId) {
            return
        }

        await dispatch('getRelatedDocuments', registerId)
    },

    async syncCurrentCompanyRegulationRegister ({ dispatch, getters }) {
        const register = getters.currentCompanyRegulationRegister

        if (register) {
            await dispatch(
                'getCurrentCompanyRegulationRegister',
                register.register_id
            )
        }
    }
}

const mutations = {
    MARK_CURRENT_REGISTER_VIDEO_AS_SEEN (state, videoId) {
        state.currentCompanyRegulationRegister.register.videos.forEach(
            video => {
                if (video.id === videoId) {
                    Vue.set(video, 'seen', true)
                }
            }
        )
    },

    SET_CURRENT_COMPANY_REGULATION_REGISTER (state, register) {
        state.currentCompanyRegulationRegister = register
    },

    UPDATE_COMPANY_REGULATION_REGISTER (state, { registerId, payload }) {
        const index = findIndex(state.companyRegulationRegisters, {
            register_id: registerId
        })

        if (index) {
            let companyRegulationRegister =
                state.companyRegulationRegisters[index]

            // Update basic company regulation register item
            state.companyRegulationRegisters[index] = {
                ...companyRegulationRegister,
                ...payload
            }

            // Also update current regulation register if needed
            if (
                state.currentCompanyRegulationRegister &&
                state.currentCompanyRegulationRegister.register_id ===
                    companyRegulationRegister.register_id
            ) {
                state.currentCompanyRegulationRegister = {
                    ...state.currentCompanyRegulationRegister,
                    ...payload
                }
            }
        }
    },

    SET_REGISTER_CHANGE_HISTORY (state, changeHistory) {
        state.registerChangeHistory = changeHistory
    },

    SET_REQUIREMENTS (state, requirements) {
        state.requirements = requirements
    },

    SET_STEP_REQUIREMENTS (state, requirements) {
        state.stepRequirements = requirements.map(requirement => {
            if (
                requirement.type ===
                TYPES_REGISTER_OF_REQUIREMENTS.LEGAL_REGULATORY_REQUIREMENTS
            ) {
                const {
                    description,
                    interested_party: interestedParty
                } = requirement.law

                return {
                    ...requirement,
                    description,
                    interested_party: interestedParty
                }
            }

            return requirement
        })
    },

    SET_COMPANY_REGULATION_REGISTERS (state, registers) {
        state.companyRegulationRegisters = [...registers]
    },

    UPDATE_CURRENT_COMPANY_REGULATION_REGISTER_STATUS (state, status) {
        state.currentCompanyRegulationRegister.status = status
    },

    SET_REGISTER_DETAILS (state, registerDetails) {
        state.registerDetails = registerDetails
    },

    SET_REGISTER_PROPERTIES (state, registerProperties) {
        state.registerProperties = [
            ...registerProperties.map(property => {
                const { value, ...rest } = property

                return {
                    ...rest,
                    value: { value }
                }
            })
        ]
    },

    SET_REGISTERS_AND_MODULES (state, registersAndModules) {
        state.registersAndModules = registersAndModules
    },
    SET_RELATED_DOCUMENTS (state, relatedDocuments) {
        state.relatedDocuments = relatedDocuments
    },
    SET_ADDITIONAL_RELATED_DOCUMENT (state, relatedDocument) {
        state.relatedDocuments = relatedDocument
    },
    RESET_RELATED_DOCUMENTS (state) {
        state.relatedDocuments = []
    }
}

const getters = {
    requirements: state => state.requirements,
    stepRequirements: state => state.stepRequirements,
    currentCompanyRegulationRegister: state =>
        state.currentCompanyRegulationRegister,
    companyRegulationRegisters: state => state.companyRegulationRegisters,
    companyRegulationRegisterByType: state => type => {
        const register = find(
            state.companyRegulationRegisters,
            companyRegulationRegister => {
                const { register } = companyRegulationRegister

                return register && register.type && register.type.name === type
            }
        )

        return register
    },
    registerByStep: state => stepId => {
        const register = find(
            state.companyRegulationRegisters,
            companyRegulationRegister => {
                const { register } = companyRegulationRegister

                return register.step_id === stepId
            }
        )

        return register
    },
    hasConfirmedInitialRequirements: (
        state,
        getters,
        rootState,
        rootGetters
    ) => {
        const regulation = rootGetters['regulation/currentRegulation']

        if (!regulation) {
            return false
        }

        const { pivot } = regulation

        return pivot.requirements_confirmed
    },
    isRegisterOwner: (state, getters) => (registerType, companyMemberId) => {
        const companyRegulationRegister = getters.companyRegulationRegisterByType(
            registerType
        )

        return (
            companyRegulationRegister &&
            companyRegulationRegister.owner_company_member_id ===
                companyMemberId
        )
    },
    isCurrentUserRegisterOwner: (state, getters, rootState, rootGetters) => {
        const registerType =
            state.currentCompanyRegulationRegister.register.type.name

        const companyMember = rootGetters['account/companyMember']

        return rootGetters['register/isRegisterOwner'](
            registerType,
            companyMember.id
        )
    },
    registerDetails: state => state.registerDetails,
    registerProperties: state => state.registerProperties,
    registersAndModules: state => state.registersAndModules,
    registerChangeHistory: state => state.registerChangeHistory,
    relatedDocuments: state => state.relatedDocuments,
    currentRegisterVideo: state =>
        get(state, 'currentCompanyRegulationRegister.register.videos[0]', null)
}

export const register = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
