<template>
    <div id="root">
        <div
            :class="[
                'app',
                showMainNav ? 'app--with-nav' : 'app--with-header',
                { 'app--with-side-panel': isExpertaVisible }
            ]"
        >
            <app-navigation v-if="showMainNav" />
            <app-header v-else />

            <main
                id="main"
                role="main"
                :class="[
                    'app__content',
                    {
                        'app__content--scrollable':
                            !showMainNav || isPreonboardingRoute()
                    }
                ]"
            >
                <div v-if="impersonated" class="app__impersonated">
                    YOU ARE ON PRODUCTION ACCOUNT OF {{ user.email }}
                </div>

                <div v-if="areWidgetsVisible" class="app__notifications">
                    <payment-plan-info />
                    <notifications
                        v-if="!areRevisionNotificationsHidden"
                        icon="update"
                        :content="
                            $t(
                                'APP.BANNERS.INTRODUCE_NEW_ISO_27001_2022_REVISION'
                            )
                        "
                    />
                    <notification-preonboarding />
                </div>

                <router-view />

                <modal-onboarding-meeeting />
                <app-error-modal />
            </main>

            <section v-if="isExpertaVisible" class="app__side-panel">
                <experta />
            </section>
        </div>
        <loader-overlay v-if="appLoading" />
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { assign, mapValues, values } from 'lodash-es'
import AppHeader from '@src/components/AppHeader'
import AppNavigation from '@src/containers/AppNavigation/AppNavigation'
import PaymentPlanInfo from '@src/components/app/PaymentPlanInfo'
import Notifications from '@src/components/app/Notifications'
import AppErrorModal from '@/components/shared/AppErrorModal'
import Experta from '@/components/experta/Experta'
import { pricingPlansService } from '@/services/pricing'
import { EventBus } from '@/EventBus'
import { config } from '@/config'
import { permissionControl, emptyVuex } from '@/mixins'
import {
    VUE_APP_GTM_TRACKING_ID,
    ISO_27001_ROUTE_PARAM,
    ISO_27001_2022_REVISION_NAME
} from '@/constants'
import { ROUTES_PREONBOARDING } from '@src/modules/preonboarding/constants'
import ModalOnboardingMeeeting from '@src/modules/preonboarding/modals/ModalOnboardingMeeeting'
import NotificationPreonboarding from '@src/modules/preonboarding/NotificationPreonboarding'
import { authService } from '@/services/auth'
import { DATE_FORMATS } from '@/services/utils/date'
import { loadScript } from '@/services/utils/dom'
import moment from 'moment'
import axios from 'axios'
import { getCookie, setCookie } from './services/localStorage'
import { ACCESS_TOKEN_EXPIRATION } from './constants/account'
import { ROUTES_AUTH } from '@src/modules/auth/constants'

const revisionNotificationsNotAllowedPaths = [
    ROUTES_PREONBOARDING.BASE.name,
    ROUTES_AUTH.LOGIN.name
]

export default {
    components: {
        AppHeader,
        AppNavigation,
        PaymentPlanInfo,
        Notifications,
        AppErrorModal,
        Experta,
        ModalOnboardingMeeeting,
        NotificationPreonboarding
    },

    mixins: [permissionControl, emptyVuex],

    data () {
        return {
            tokenExpDate: null,
            now: null,
            timeout: null
        }
    },

    computed: {
        ...mapState('account', ['user', 'impersonated']),
        ...mapGetters('account', ['isLoggedIn', 'user', 'isConsultant']),
        ...mapGetters('company', [
            'isOnboardingCompleted',
            'companyHasEarlyAccess',
            'regulationData'
        ]),

        appLoading () {
            return this.$store.state.appLoading
        },

        areWidgetsVisible () {
            return this.isLoggedIn && this.isOnboardingCompleted
        },

        areRevisionNotificationsHidden () {
            const path = this.$route.path

            const isPathIncluded = revisionNotificationsNotAllowedPaths.some(
                notAllowedPath => path.includes(notAllowedPath)
            )

            return (
                isPathIncluded ||
                this.regulationData?.revision?.name ===
                    ISO_27001_2022_REVISION_NAME
            )
        },

        showMainNav () {
            return (
                this.isLoggedIn &&
                (this.isOnboardingCompleted || this.isConsultant)
            )
        },

        isExpertaVisible () {
            return (
                this.companyHasEarlyAccess &&
                this.isLoggedIn &&
                !this.isPreonboardingRoute()
            )
        }
    },

    watch: {
        $route (to) {
            this.handleGTM(to)
        },
        now: {
            handler (value) {
                if (this.tokenExpDate && value && this.tokenExpDate <= value) {
                    clearTimeout(this.timeout)
                    this.updateToken()
                } else {
                    this.timeout = setTimeout(() => {
                        this.now++
                    }, 1000)
                }
            },
            immediate: true // This ensures the watcher is triggered upon creation
        }
    },

    async created () {
        if (this.isLoggedIn) {
            const lastActive = this.user?.last_active

            this.tokenExpDate = moment(lastActive, DATE_FORMATS.API)
                .add(ACCESS_TOKEN_EXPIRATION - 1, 'days')
                .unix()

            this.now = moment().unix()
        }

        EventBus.$on('logout', this.handleLogout)
    },

    mounted () {
        loadScript({
            src: pricingPlansService.getDomainPath()
        })
    },

    beforeDestroy () {
        EventBus.$off('logout', this.handleLogout)
    },

    methods: {
        ...mapActions('account', ['logout']),

        handleLogout () {
            this.$mixpanel.reset()
            this.logout()
        },

        async updateToken () {
            const refreshToken = getCookie('refreshToken')

            if (refreshToken) {
                const response = await authService.refreshToken({
                    refreshToken
                })

                setCookie('token', response.data.token)

                assign(axios.defaults.headers, {
                    Authorization: `Bearer ${response.data.token}`
                })
            }
        },

        getPathWithoutCompanyNameAndStandard (path, standard, subdomain) {
            if (standard) {
                return this.cutPath(path, standard)
            }
            return this.cutPath(path, subdomain)
        },

        cutPath (path, partPath) {
            return decodeURI(
                path.slice(path.search(partPath) + partPath.length)
            )
        },

        handleGTM (currentRoute) {
            if (
                VUE_APP_GTM_TRACKING_ID &&
                this.$gtm.enabled() &&
                window.dataLayer
            ) {
                if (currentRoute?.params?.subdomain) {
                    this.pushDataLayerGTMCommonTrack(currentRoute)
                }
                if (
                    currentRoute.name ===
                    ROUTES_PREONBOARDING.COMPANY_SETUP.name
                ) {
                    this.pushDataLayerGTMNewAccount()
                }
            }
        },

        pushDataLayerGTMCommonTrack (currentRoute) {
            const { name, fullPath, params } = currentRoute

            // prepare data
            const data = {
                event: 'sendVirtualPageview',
                pageUrl: this.getPathWithoutCompanyNameAndStandard(
                    fullPath,
                    params.standard,
                    params.subdomain
                ),
                pageTitle: name,
                companyName: params.subdomain,
                standard: params.standard || ISO_27001_ROUTE_PARAM
            }
            // push to GTM
            window.dataLayer.push(data)
        },

        pushDataLayerGTMNewAccount () {
            // prepare data
            const data = {
                event: 'account_created'
            }
            // push to GTM
            window.dataLayer.push(data)
        },

        isPreonboardingRoute () {
            const routeName = this.$route.name

            const preonboardingRoutes = values(
                mapValues(ROUTES_PREONBOARDING, 'name')
            )

            return preonboardingRoutes.includes(routeName)
        }
    },

    errorCaptured (err, vm, info) {
        !config.isProductionEnv() && console.log(err, vm, info)
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue-select/dist/vue-select.css"></style>

<style lang="scss">
@import '@src/styles/app.scss';
</style>

<style lang="scss" scoped>
@import '@style/sfc.scss';

.app {
    display: flex;
    min-height: 100vh;

    &--with-header {
        height: 100vh;
        flex-direction: column;
    }

    &__content {
        @include thin-scrollbar;
        position: relative;
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow-y: overlay;

        .app--with-nav & {
            height: 100vh;
            margin-left: rem($width-app-navigation-collapsed);
        }

        &--scrollable {
            @include thin-scrollbar;
            overflow-y: auto;
        }
    }

    &__side-panel {
        position: relative;
        width: rem($cf-side-panel-width);
        min-width: rem($cf-side-panel-width);
    }

    &__impersonated {
        padding: rem(8) rem(15);
        font-size: rem(18);
        font-weight: 700;
        line-height: calc(24 / 18);
        text-align: center;
        background-color: $cf-color-danger;
        color: $color-white;
    }
}
</style>
