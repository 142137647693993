import {
    ROUTES,
    REGISTER_WIZARD_ROUTES,
    ACCOUNT_ROUTES,
    COMPANY_SETTINGS_ROUTES
} from '@src/constants'
import { ROUTES_COMPLIANCE } from '@src/modules/compliance/constants'
import { ROUTES_SECURITY_OBJECTIVES } from '@src/modules/registers/security-objectives/constants'
import { ROUTES_COMPANY_SETTINGS } from '@src/modules/company-settings/constants'

export const PRIMARY_NAV = 'primary'
export const SECONDARY_NAV = 'secondary'

export const NAVIGATION_IDS = {
    CONSULTANT_DASHBOARD: 'consultantDashboard',
    COMPLIANCE: 'compliance',
    DOCUMENTS: 'documents',
    REGISTERS_AND_MODULES: 'registersAndModules',
    REPORTING_DASHBOARD: 'reportingDashboard',
    RESPONSIBILITY_MATRIX: 'responsibilityMatrix',
    WORK: 'work',
    TRAINING_PROGRAM: 'trainingProgram',
    COMPANY_SETTINGS: 'companySettings',
    HELP: 'help',
    ACCOUNT: 'account'
}

const routeMapping = [
    {
        id: NAVIGATION_IDS.COMPLIANCE,
        nav: PRIMARY_NAV,
        routes: [
            ROUTES_COMPLIANCE.STEPS.name,
            ROUTES_COMPLIANCE.AUDIT_AND_EVIDENCE.name
        ]
    },
    {
        id: NAVIGATION_IDS.REPORTING_DASHBOARD,
        nav: PRIMARY_NAV,
        routes: [
            REGISTER_WIZARD_ROUTES.REPORTING.INDEX.name,
            REGISTER_WIZARD_ROUTES.REPORTING.PROJECT_STATUS_DASHBOARD.name,
            REGISTER_WIZARD_ROUTES.REPORTING.COMPLIANCE_DASHBOARD.name,
            REGISTER_WIZARD_ROUTES.REPORTING.PERFORMANCE_DASHBOARD.name,
            ROUTES_SECURITY_OBJECTIVES.BASE.name,
            REGISTER_WIZARD_ROUTES.REPORTING.MANAGEMENT_REVIEW.ACTIVITIES.WIZARD
                .name
        ]
    },
    {
        id: NAVIGATION_IDS.WORK,
        nav: PRIMARY_NAV,
        routes: [
            ROUTES.MY_WORK.OVERVIEW.name,
            ROUTES.MY_WORK.TASKS.MY_TASKS.name,
            ROUTES.MY_WORK.TASKS.TASKS_FOR_OTHERS.name,
            ROUTES.MY_WORK.STEPS.name,
            ROUTES.MY_WORK.DOCUMENTS.name,
            ROUTES.MY_WORK.DISCUSSIONS.name,
            ROUTES.MY_WORK.REGISTERS.name
        ]
    },
    {
        id: NAVIGATION_IDS.COMPANY_SETTINGS,
        nav: PRIMARY_NAV,
        routes: [
            ROUTES_COMPANY_SETTINGS.USER_MANAGEMENT.name,
            COMPANY_SETTINGS_ROUTES.BASIC_INFORMATION.name,
            COMPANY_SETTINGS_ROUTES.SECURITY.INDEX.name,
            COMPANY_SETTINGS_ROUTES.PROJECT_SETTINGS.name,
            COMPANY_SETTINGS_ROUTES.INTEGRATIONS.name
        ]
    },
    {
        id: NAVIGATION_IDS.ACCOUNT,
        nav: SECONDARY_NAV,
        routes: [ACCOUNT_ROUTES.MY_PROFILE.name, ACCOUNT_ROUTES.SETTINGS.name]
    }
]

export function initialExpandedSubNavigation (routeName) {
    return routeMapping.find(mapping => {
        if (mapping.routes.some(route => route === routeName)) {
            return mapping
        }

        return false
    })
}
