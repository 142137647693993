var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"app-nav",on:{"mouseover":_vm.mouseover,"mouseleave":_vm.mouseleave}},[_c('div',{ref:"topBar",staticClass:"app-nav__top-wrap"},[_c('button',{staticClass:"app-nav__home-action",attrs:{"type":"button"},on:{"click":_vm.handleNavigateHome}},[(_vm.companyHasWhiteLabelLogo)?_c('div',{staticClass:"app-nav__whitelabel-wrap"},[(_vm.company.whitelabel_logo)?_c('img',{staticClass:"app-nav__whitelabel-img app-nav__whitelabel-img--big",attrs:{"src":_vm.company.whitelabel_logo}}):_c('img',{staticClass:"app-nav__whitelabel-img app-nav__whitelabel-img--big",attrs:{"src":require(`@src/assets/images/logo.png`)}}),(_vm.company.whitelabel_logo_small)?_c('img',{staticClass:"app-nav__whitelabel-img app-nav__whitelabel-img--small",attrs:{"src":_vm.company.whitelabel_logo_small}}):_c('img',{staticClass:"app-nav__whitelabel-img app-nav__whitelabel-img--small",attrs:{"src":require(`@src/assets/images/logo-small.png`)}})]):_c('img',{staticClass:"app-nav__logo-img",attrs:{"src":require(`@src/assets/images/logo.png`)}})]),_c('p',{staticClass:"app-nav__standard"},[_c('app-icon',{attrs:{"icon":"icon-privacy","size":"sm"}}),_c('span',{staticClass:"app-nav__standard_text"},[_vm._v(_vm._s(_vm.$t('COMMON.ISO_27001')))])],1)]),_vm._l((_vm.navigationLists),function(list,i){return _c('ul',{key:i,ref:list.id,refInFor:true,class:[`app-nav__list app-nav__list--is-${list.additionalClass}`],style:({
            height:
                list.id === _vm.PRIMARY_NAV
                    ? `calc(100vh - ${_vm.topBarHeight + _vm.secondaryNavHeight}px)`
                    : 'auto'
        })},[_vm._l((list.navigation),function(item){return _c('li',{key:item.id,class:[
                `app-nav__list-item app-nav__list-item--is-${list.additionalClass}`
            ]},[(item.url)?_c('router-link',{class:[
                    'app-nav__action',
                    {
                        'app-nav__action--is-active':
                            _vm.$route.name === item.url.name
                    }
                ],attrs:{"to":item.url}},[_c('app-icon',{attrs:{"icon":`icon-${item.icon}`,"size":"sm"}}),_c('span',{staticClass:"app-nav__action-text"},[_vm._v(" "+_vm._s(item.label)+" "),(item.haveTag)?_c('span',{staticClass:"app-nav__action-tag"},[_vm._v(_vm._s(item.tag))]):_vm._e()])],1):_c('button',{class:[
                    'app-nav__action',
                    {
                        'app-nav__action--is-sub-nav-expanded': !!item.subNavigationMaxHeight
                    }
                ],attrs:{"type":"button"},on:{"click":item.action}},[_c('app-icon',{attrs:{"icon":`icon-${item.icon}`,"size":"sm"}}),_c('span',{staticClass:"app-nav__action-text"},[_vm._v(_vm._s(item.label))]),(item.isExpandable)?_c('app-icon',{attrs:{"icon":"icon-arrow","size":"custom"}}):_vm._e()],1),(item.isExpandable)?_c('ul',{ref:item.id,refInFor:true,staticClass:"app-nav__sub-list",style:({
                    'max-height': `${item.subNavigationMaxHeight}px`
                })},_vm._l((item.subNavigation),function(subItem,j){return _c('li',{key:j,staticClass:"app-nav__sub-list-item"},[(subItem.url)?_c('router-link',{class:[
                            'app-nav__sub-action',
                            {
                                'app-nav__sub-action--is-active':
                                    _vm.$route.name === subItem.url.name
                            }
                        ],attrs:{"to":subItem.url}},[_c('span',{staticClass:"app-nav__sub-action-text"},[_vm._v(" "+_vm._s(subItem.label)+" "),(subItem.haveTag)?_c('span',{staticClass:"app-nav__action-tag"},[_vm._v(_vm._s(subItem.tag))]):_vm._e()])]):_c('button',{staticClass:"app-nav__sub-action",attrs:{"type":"button"},on:{"click":subItem.action}},[_c('span',{staticClass:"app-nav__sub-action-text"},[_vm._v(_vm._s(subItem.label))])])],1)}),0):_vm._e()],1)}),(list.id === _vm.secondaryNavId)?_c('li',{class:[
                `app-nav__list-item app-nav__list-item--is-${list.additionalClass}`
            ]},[_c('p',{staticClass:"app-nav__copyright"},[_vm._v(" © "+_vm._s(new Date().getFullYear())+" "),_c('span',[_vm._v("Advisera Expert Solutions Ltd")])])]):_vm._e()],2)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }