import { mapActions, mapGetters } from 'vuex'
import { LOCAL_STORAGE_ITEMS } from '@/constants'
import { ROUTES_CONSULTANT } from '@src/modules/consultant/constants'
import {
    getLocalStorageItem,
    removeLocalStorageItem,
    doesLocalStorageItemExist
} from '@/services/localStorage'
import { ROUTES_PREONBOARDING } from '@src/modules/preonboarding/constants'
import { ROUTES_AUTH } from '@/modules/auth/constants'

export default {
    computed: {
        ...mapGetters('company', ['companyExists', 'isOnboardingCompleted']),
        ...mapGetters('account', ['user', 'isConsultant'])
    },

    methods: {
        ...mapActions('account', ['redirectHome']),

        redirectToCompaniesManagement () {
            return this.$router.push(ROUTES_CONSULTANT.BASE).catch(() => {})
        },

        redirectToLogin () {
            return this.$router.replace(ROUTES_AUTH.LOGIN).catch(() => {})
        },

        redirectToCreateCompany () {
            return this.$router
                .push(ROUTES_PREONBOARDING.COMPANY_SETUP)
                .catch(() => {})
        },

        async redirectAfterLogin () {
            if (this.isConsultant) {
                return this.redirectToCompaniesManagement()
            }

            if (
                doesLocalStorageItemExist(
                    LOCAL_STORAGE_ITEMS.LOGIN_REDIRECT_PATH
                )
            ) {
                window.location.href = getLocalStorageItem(
                    LOCAL_STORAGE_ITEMS.LOGIN_REDIRECT_PATH
                )

                return removeLocalStorageItem(
                    LOCAL_STORAGE_ITEMS.LOGIN_REDIRECT_PATH
                )
            }

            if (!this.companyExists || !this.isOnboardingCompleted) {
                return this.$router
                    .push(ROUTES_PREONBOARDING.COMPANY_SETUP)
                    .catch(() => {})
            }

            this.redirectHome()
        }
    }
}
