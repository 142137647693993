var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"root"}},[_c('div',{class:[
            'app',
            _vm.showMainNav ? 'app--with-nav' : 'app--with-header',
            { 'app--with-side-panel': _vm.isExpertaVisible }
        ]},[(_vm.showMainNav)?_c('app-navigation'):_c('app-header'),_c('main',{class:[
                'app__content',
                {
                    'app__content--scrollable':
                        !_vm.showMainNav || _vm.isPreonboardingRoute()
                }
            ],attrs:{"id":"main","role":"main"}},[(_vm.impersonated)?_c('div',{staticClass:"app__impersonated"},[_vm._v(" YOU ARE ON PRODUCTION ACCOUNT OF "+_vm._s(_vm.user.email)+" ")]):_vm._e(),(_vm.areWidgetsVisible)?_c('div',{staticClass:"app__notifications"},[_c('payment-plan-info'),(!_vm.areRevisionNotificationsHidden)?_c('notifications',{attrs:{"icon":"update","content":_vm.$t(
                            'APP.BANNERS.INTRODUCE_NEW_ISO_27001_2022_REVISION'
                        )}}):_vm._e(),_c('notification-preonboarding')],1):_vm._e(),_c('router-view'),_c('modal-onboarding-meeeting'),_c('app-error-modal')],1),(_vm.isExpertaVisible)?_c('section',{staticClass:"app__side-panel"},[_c('experta')],1):_vm._e()],1),(_vm.appLoading)?_c('loader-overlay'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }